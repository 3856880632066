import $api from "../http";

export default class CommunityService {
  static async getByRegion(regionId) {
    return $api.post("/community/getByRegion", { regionId });
  }

  static async create(name, regionId, amount, contact, phone) {
    return $api.post("/community", {
      name,
      regionId,
      amount,
      contact,
      phone,
    });
  }

  static async editAmount(id, amount) {
    return $api.put("/community/amount", {
      id,
      amount,
    });
  }

  static async numberOfAccepted(id) {
    return $api.post("/community/numberOfAccepted", {
      id,
    });
  }

  static async numberOfAcceptedOnDate(id, timestamp) {
    return $api.post("/community/numberOfAcceptedOnDate", {
      id,
      timestamp,
    });
  }

  static async numberOfAcceptedOnDate(id) {
    return $api.post("/community/numberOfAccepted", {
      id,
    });
  }

  static async numberOfFreeOnDate(id, timestamp) {
    return $api.post("/community/numberOfFreeOnDate", {
      id,
      timestamp,
    });
  }

  static async numberOfFree(id) {
    return $api.post("/community/numberOfFree", {
      id,
    });
  }
}
