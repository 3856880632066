import {
  Button,
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import UserService from "../../services/UserService";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import ukLocale from "date-fns/locale/uk";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LogisticsService from "../../services/LogisticsService";
import moment from "moment";
import { DataGrid, ukUA } from "@mui/x-data-grid";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ChooseCarrier() {
  const [timestamp, setTimestamps] = useState(Date());
  const [values, setValues] = useState({
    driver: "",
    cityDeparture: "",
    addressDeparture: "",
    addressDestination: "",
    quantity: 0,
  });
  const [driver, setDriver] = useState({
    cityDeparture: "",
    addressDeparture: "",
    timestampDeparture: "",
    destinationAddress: "",
    quantity: "",
  });
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [dataRows, setDataRows] = useState([]);

  const columns = [
    // { field: "_id", headerName: "id", width: 90,display: "none"  },
    {
      field: "userId",
      headerName: "ПІБ",

      width: 200,
      valueGetter: (params) => {
        if (params.row?.userId?.fullName) {
          return String(params.row?.userId?.fullName);
        } else {
          return "";
        }
      },
      type: "string",
    },
    { field: "cityDeparture", headerName: "Місто відправлення", width: 200 },
    {
      field: "addressDeparture",
      headerName: "Адреса відправлення",
      width: 300,
    },
    {
      field: "timestampDeparture",
      headerName: "Час відправлення",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy HH:mm:ss");
      },
    },
    {
      field: "destinationAddress",
      headerName: "Адреса призначення",
      width: 400,
    },
    { field: "quantity", headerName: "Кількість", width: 200 },
  ];

  const [drivers, setDrivers] = useState({ options: [] });
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [errorAddress, setErrorAddress] = React.useState("");
  const [error, setError] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const checkInputs = (values) => {
    if (values.addressDeparture.length < 1) {
      setErrorAddress("Поле має бути заповнено!");
      return true;
    }
    setErrorAddress("");
    setError("");

    return false;
  };

  const getDriverInfo = () => {
    try {
      LogisticsService.getInfoByDriver(
        drivers.options.find((driver) => driver.label === values.driver)._id
      )
        .then((response) => {
          if (response.data.logistic != null) {
            if (response.data.logistic) {
              setDriver(response.data.logistic);
            }
          }
        })
        .catch((e) => {
          console.log(e.response?.data?.message);
        });
    } catch (error) {}
  };

  const getDriversInfo = () => {
    try {
      setDataGridLoading(true);
      LogisticsService.getInfoByDrivers()
        .then((response) => {
          if (response.data.logistic != null) {
            if (response.data.logistic) {
              setDataRows(response.data.logistic);
            }
          }
        })
        .catch((e) => {
          console.log(e.response?.data?.message);
        })
        .finally(() => {
          setDataGridLoading(false);
        });
    } catch (error) {}
  };

  const addAction = (values) => {
    if (checkInputs(values)) return;
    setLoading(true);
    LogisticsService.create(
      drivers.options.find((driver) => driver.label === values.driver)._id,
      values.cityDeparture,
      values.addressDeparture,
      timestamp,
      values.addressDestination,
      values.quantity
    )
      .then((response) => {
        setValues({
          driver: "",
          cityDeparture: "",
          addressDeparture: "",
          addressDestination: "",
          quantity: 0,
        });
        getDriverInfo();
        setOpen(true);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    getDriverInfo();
    getDriversInfo();
    // eslint-disable-next-line
  }, [values.driver]);

  useEffect(() => {
    getDrivers();
    getDriversInfo();
  }, []);

  const getDrivers = () => {
    UserService.getDrivers()
      .then((response) => {
        const drivers = {
          options: response.data.users.map((user) => {
            return { label: user.fullName, _id: user._id };
          }),
        };
        setDrivers(drivers);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <Autocomplete
        disablePortal
        {...drivers}
        renderInput={(params) => (
          <TextField
            {...params}
            value={values.driver}
            onChange={handleChange("driver")}
            onSelect={handleChange("driver")}
            label="ПІБ"
          />
        )}
      />

      <TextField
        id="outlined-basic"
        label="Місто відправлення"
        variant="outlined"
        value={values.cityDeparture}
        onChange={handleChange("cityDeparture")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <TextField
        id="outlined-basic"
        label="Адреса відправлення"
        variant="outlined"
        value={values.addressDeparture}
        onChange={handleChange("addressDeparture")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ukLocale}
      >
        <DateTimePicker
          label="Час відправлення"
          value={timestamp}
          mask={"__.__.____"}
          minDate={new Date("2017-01-01")}
          onChange={(timestamp) => {
            setTimestamps(timestamp);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <TextField
        id="outlined-basic"
        label="Адреса призначення"
        placeholder="Машинобудівників 49"
        variant="outlined"
        value={values.addressDestination}
        error={errorAddress.length > 0 ? true : false}
        helperText={errorAddress}
        onChange={handleChange("addressDestination")}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <TextField
        id="outlined-basic"
        label="Кількість"
        placeholder="10"
        variant="outlined"
        value={values.quantity}
        onChange={handleChange("quantity")}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <Button
        variant="outlined"
        color="secondary"
        startIcon={<EditIcon />}
        onClick={() => {
          addAction(values);
        }}
      >
        Призначити
      </Button>

      {loading ? <CircularProgress /> : null}

      <DataGrid
        autoHeight
        rows={dataRows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[5, 10, 50]}
        // checkboxSelection
        disableSelectionOnClick
        // density={"standard"}
        loading={dataGridLoading}
        getRowId={(row) => row._id}
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Все успішно!
        </Alert>
      </Snackbar>
    </Paper>
  );
}
