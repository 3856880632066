import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactInputMask from "react-input-mask";
import ApplicationService from "../../services/ApplicationService";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import CommunityService from "../../services/CommunityService";
import RegionService from "../../services/RegionService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ukLocale from "date-fns/locale/uk";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddApplication() {
  const [values, setValues] = useState({
    surname: "",
    name: "",
    patronymic: "",
    city: "",
    address: "",
    ipn: "",
    passport: "",
    passportWhenIssued: "",
    passportByWhomWasIssued: "",
    birthday: "",

    phone: "",
    status: false,
    comment: "",
    disabled: false,
    limitedMobility: false,
    community: "",
    payment: false,
    verify: false,
    evacuationDirection: "",
    evacuationTime: Date(),
  });

  const [applications, setApplications] = useState([]);
  const [fiend, setFiend] = useState(false);

  const [regions, setRegions] = useState({ options: [] });
  const [communities, setCommunities] = useState({ options: [] });

  const [free, setFree] = useState(0);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCity, setErrorCity] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [error, setError] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorRes, setErrorRes] = useState("");
  const [openError, setOpenError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const checkInputs = (values) => {
    if (values.payment && values.ipn.length < 1) {
      setError("Помилка ІПН!");
      return true;
    }
    if (values.payment && values.passport.length < 5) {
      setError("Помилка паспорт!");
      return true;
    }
    if (values.evacuationDirection.length < 1) {
      setError("Помилка напрямок евакуації!");
      return true;
    }
    if (values.phone.length !== 13 || values.phone.length < 1) {
      setErrorPhone("Помилка телефону!");
      return true;
    }
    setErrorPhone("");
    if (
      values.surname.length < 1 ||
      values.name.length < 1 ||
      values.patronymic.length < 1
    ) {
      setError("Усі поля мають бути заповнені!");
      return true;
    }

    if (values.city.length < 1) {
      setError("Усі поля мають бути заповнені!");
      return true;
    }
    if (values.address.length < 1) {
      setError("Усі поля мають бути заповнені!");
      return true;
    }
    setErrorCity("");
    setError("");
    return false;
  };

  const addAction = (values) => {
    if (checkInputs(values)) return;
    setLoading(true);

    ApplicationService.create(
      values.surname.charAt(0).toUpperCase() + values.surname.slice(1).trim(),
      values.name.charAt(0).toUpperCase() + values.name.slice(1).trim(),
      values.patronymic.charAt(0).toUpperCase() +
        values.patronymic.slice(1).trim(),
      values.city.charAt(0).toUpperCase() + values.city.slice(1),
      values.address.charAt(0).toUpperCase() + values.address.slice(1),
      values.ipn.trim(),
      values.passport.toUpperCase(),
      moment(
        moment(values.passportWhenIssued).format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      ).toDate(),
      values.passportByWhomWasIssued.toUpperCase(),
      moment(
        moment(values.birthday).format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      ).toDate(),
      values.phone,
      values.status ? "З поселенням" : "Без поселення",
      values.comment,
      values.disabled,
      values.children,
      values.payment,
      values.limitedMobility,
      values.evacuationDirection,
      values.evacuationTime
    )
      .then((response) => {
        setValues({
          ...values,
          surname: "",
          name: "",
          patronymic: "",
          city: "",
          address: "",
          ipn: "",
          passport: "",
          phone: "",
          status: false,
          comment: "",
          disabled: false,
          children: false,
          payment: false,
          limitedMobility: false,
        });
        setOpen(true);
        setFiend(false);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
        setErrorRes(e.response?.data?.message);
        setOpenError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const getCommunities = (id) => {
  //   CommunityService.getByRegion(id)
  //     .then((response) => {
  //       const communities = {
  //         options: response.data.communities.map((community) => {
  //           return {
  //             label: community.name,
  //             _id: community._id,
  //             amount: community.amount,
  //           };
  //         }),
  //       };
  //       setCommunities(communities);
  //     })
  //     .catch((e) => {
  //       console.log(e.response?.data?.message);
  //     });
  // };

  const handleChange = (prop) => (event) => {
    setFiend(false);
    if (
      prop === "limitedMobility" ||
      prop === "disabled" ||
      prop === "status" ||
      prop === "verify" ||
      prop === "children" ||
      prop === "payment"
    ) {
      setValues({ ...values, [prop]: event.target.checked });
    } else if (
      prop === "birthday" ||
      prop === "evacuationTime" ||
      prop === "passportWhenIssued"
    ) {
      setValues({ ...values, [prop]: event });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const getNumberOfFree = (id) => {
    CommunityService.numberOfFree(id)
      .then((response) => {
        setFree(response.data.numberOfFree);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  };

  const searchAction = (values) => {
    setLoading(true);
    ApplicationService.findByIpn(values.ipn)
      .then((response) => {
        setFiend(true);
        setApplications(response.data.applications);
        console.log(response.data.applications);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="h6">{`Вільно на ${moment(Date()).format(
          "DD.MM.YYYY"
        )}: ${free}`}</Typography>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<RefreshIcon />}
          onClick={() => {
            getNumberOfFree(
              communities.options.find(
                (community) => community.label === values.community
              )._id
            );
          }}
        >
          Оновити
        </Button>
      </div> */}

      <TextField
        id="outlined-basic"
        label="Напрямок евакуації"
        variant="outlined"
        value={values.evacuationDirection}
        onChange={handleChange("evacuationDirection")}
        error={error.length > 0 ? true : false}
        helperText={error}
      />

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ukLocale}
      >
        <DesktopDatePicker
          label="День евакуації"
          value={values.evacuationTime}
          mask={"__.__.____"}
          minDate={new Date("2023-01-01")}
          onChange={handleChange("evacuationTime")}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <TextField
        id="outlined-basic"
        label="Прізвище"
        variant="outlined"
        value={values.surname}
        onChange={handleChange("surname")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />
      <TextField
        id="outlined-basic"
        label="Ім'я"
        variant="outlined"
        value={values.name}
        onChange={handleChange("name")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />
      <TextField
        id="outlined-basic"
        label="По батькові"
        variant="outlined"
        value={values.patronymic}
        onChange={handleChange("patronymic")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <ReactInputMask
        mask="9999999999"
        value={values.ipn}
        disabled={false}
        maskChar=""
        onChange={handleChange("ipn")}
      >
        {() => (
          <TextField
            label="ІПН"
            placeholder="9999999999"
            error={error.length > 0 ? true : false}
            helperText={error}
          />
        )}
      </ReactInputMask>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.payment}
              onChange={handleChange("payment")}
            />
          }
          label="Отримує виплату"
        />
      </FormGroup>
      {values.payment ? (
        <TextField
          id="outlined-basic"
          label="Паспорт серія, номер"
          variant="outlined"
          value={values.passport}
          error={error.length > 0 ? true : false}
          helperText={error}
          onChange={handleChange("passport")}
        />
      ) : null}

      {values.payment ? (
        <TextField
          id="outlined-basic"
          label="Ким був виданий паспорт"
          variant="outlined"
          value={values.passportByWhomWasIssued}
          error={error.length > 0 ? true : false}
          helperText={error}
          onChange={handleChange("passportByWhomWasIssued")}
        />
      ) : null}

      {values.payment ? (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ukLocale}
        >
          <DesktopDatePicker
            label="Коли був виданий паспорт"
            value={values.passportWhenIssued}
            mask={"__.__.____"}
            minDate={new Date("1900-01-01")}
            onChange={handleChange("passportWhenIssued")}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      ) : null}

      <TextField
        id="outlined-basic"
        label="Фактичне Місто"
        placeholder="Краматорськ"
        variant="outlined"
        value={values.city}
        error={errorCity.length > 0 ? true : false}
        helperText={errorCity}
        onChange={handleChange("city")}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <TextField
        id="outlined-basic"
        label="Фактична Адреса"
        placeholder="Машинобудівників 49"
        variant="outlined"
        value={values.address}
        error={errorAddress.length > 0 ? true : false}
        helperText={errorAddress}
        onChange={handleChange("address")}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />
      <ReactInputMask
        mask="+389999999999"
        value={values.phone}
        disabled={false}
        maskChar=""
        error={error.length > 0 ? true : false}
        helperText={error}
        onChange={handleChange("phone")}
      >
        {() => (
          <TextField
            error={errorPhone.length > 0 ? true : false}
            helperText={errorPhone}
            label="Номер телефону"
            placeholder="+380123456789"
          />
        )}
      </ReactInputMask>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ukLocale}
      >
        <DesktopDatePicker
          label="День народження"
          value={values.birthday}
          mask={"__.__.____"}
          minDate={new Date("1900-01-01")}
          onChange={handleChange("birthday")}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.disabled}
              onChange={handleChange("disabled")}
            />
          }
          label="Особа з інвалідністю"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.children}
              onChange={handleChange("children")}
            />
          }
          label="Дитина"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.limitedMobility}
              onChange={handleChange("limitedMobility")}
            />
          }
          label="Маломобільний"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.status}
              onChange={handleChange("status")}
            />
          }
          label="З поселенням"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.verify}
              onChange={handleChange("verify")}
            />
          }
          label="Даю згоду на обробку персональних даних"
        />
      </FormGroup>
      <TextField
        id="outlined-basic"
        label="Коментар"
        placeholder="Інвалід"
        variant="outlined"
        value={values.comment}
        onChange={handleChange("comment")}
      />
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<SearchIcon />}
        onClick={() => {
          searchAction(values);
        }}
      >
        Пошук
      </Button>
      {loading ? <CircularProgress /> : null}
      {applications.length < 1 && fiend ? (
        <Typography>Нема записів</Typography>
      ) : null}
      {applications.map((application, i) => {
        const blackList = application.address.includes("*ЧС*");
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={blackList ? { backgroundColor: "red" } : null}
            >
              <Typography>
                Час створення заявки:{" "}
                {application.createdAt
                  ? moment(application.createdAt).format("DD.MM.yyyy HH:mm:ss")
                  : "-"}
              </Typography>
            </AccordionSummary>

            <AccordionDetails
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                ...(blackList ? { backgroundColor: "red" } : {}),
              }}
            >
              <Typography>
                ПІБ: {application.surname} {application.name}{" "}
                {application.patronymic}
              </Typography>
              <Typography>Номер телефону: {application.phone}</Typography>
              <Typography>Місто: {application.city}</Typography>
              <Typography>Адреса: {application.address}</Typography>
              <Typography>ІПН: {application.ipn}</Typography>
              <Typography>Паспорт: {application.passport}</Typography>
              <Typography>Поселення: {application.status}</Typography>

              <Typography>
                Особа з інвалідністю: {application.disabled ? "Так" : "Ні"}
              </Typography>
              <Typography>
                Спецтранспорт: {application.limitedMobility ? "Так" : "Ні"}
              </Typography>
              <Typography>Коментар: {application.comment}</Typography>
              <Typography>
                Організація: {application.organizationId.name}
              </Typography>
              <Typography>Волонтер: {application.userId.fullName}</Typography>
              <Typography>
                Дата: {moment(application.createdAt).format("DD.MM.YYYY")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {fiend ? (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<EditIcon />}
          onClick={() => {
            addAction(values);
          }}
        >
          Записати
        </Button>
      ) : null}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Все успішно!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {`Помилка! ${errorRes}`}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
