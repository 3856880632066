import $api from "../http";

export default class UserService {
  static async auth() {
    return $api.get("/user/auth");
  }
  static async getUsers() {
    return $api.get("/user/users");
  }
  static async putUser(id, email, fullName, role) {
    return $api.put("/user/user", {
      id,
      email,
      fullName,
      role,
    });
  }
  static async createUser(email, password, fullName, organization) {
    return $api.post("/user/user", {
      email,
      password,
      fullName,
      organization,
    });
  }

  static async getDrivers() {
    return $api.get("/user/drivers");
  }
}
