import { Button, Paper, TextField, CircularProgress } from "@mui/material";
import React, { useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";

import CarService from "../../services/CarService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CarrierCar() {
  const [values, setValues] = useState({
    brand: "",
    color: "",
    carNumber: "",
    driverFullName: "",
    passport: "",
  });

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [error, setError] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const checkInputs = (values) => {
    return false;
  };

  const addAction = (values) => {
    if (checkInputs(values)) return;
    setLoading(true);
    CarService.create(
      values.brand,
      values.color,
      values.carNumber,
      values.driverFullName,
      values.passport
    )
      .then((response) => {
        setValues({
          brand: "",
          color: "",
          carNumber: "",
          driverFullName: "",
          passport: "",
        });
        setOpen(true);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <TextField
        id="outlined-basic"
        label="Марка"
        variant="outlined"
        value={values.brand}
        onChange={handleChange("brand")}
        error={error.length > 0 ? true : false}
        helperText={error}
      />

      <TextField
        id="outlined-basic"
        label="Колір"
        variant="outlined"
        value={values.color}
        onChange={handleChange("color")}
        error={error.length > 0 ? true : false}
        helperText={error}
      />

      <TextField
        id="outlined-basic"
        label="Номер"
        placeholder="АА 0000 АА"
        variant="outlined"
        value={values.carNumber}
        onChange={handleChange("carNumber")}
      />

      <TextField
        id="outlined-basic"
        label="ПІБ"
        placeholder=""
        variant="outlined"
        value={values.driverFullName}
        onChange={handleChange("driverFullName")}
      />

      <TextField
        id="outlined-basic"
        label="Серія та номер паспорту"
        placeholder=""
        variant="outlined"
        value={values.passport}
        onChange={handleChange("passport")}
      />

      <Button
        variant="outlined"
        color="secondary"
        startIcon={<EditIcon />}
        onClick={() => {
          addAction(values);
        }}
      >
        Зареєструвати
      </Button>

      {loading ? <CircularProgress /> : null}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Все успішно!
        </Alert>
      </Snackbar>
    </Paper>
  );
}
