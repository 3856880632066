import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import AddCommunity from "./adminPanels/AddCommunity";
import UpdateCommunity from "./adminPanels/UpdateCommunity";
import InfoCommunity from "./adminPanels/InfoCommunity";
import AddApplication from "./adminPanels/AddApplication";
import ChooseCarrier from "./adminPanels/ChooseCarrier";
import Carrier from "./adminPanels/Carrier";
import Search from "./adminPanels/Search";
import Application from "./adminPanels/Applications";
import ApplicationCoordinator from "./adminPanels/ApplicationsCoordinator";
import ApplicationCallcenter from "./adminPanels/ApplicationsCallcenter";
import CarrierCar from "./adminPanels/CarrierCar";
import CarrierCarInfo from "./adminPanels/CarrierCarInfo";

export default function Home() {
  const [role, setRole] = useState("USER");
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setRole(localStorage.role);
  }, []);
  useEffect(() => {
    if (role === "CALLCENTER" || role === "UKRZALIZNYTSIA") {
      setValue("0");
    }
    if (role === "COORDINATOR") {
      setValue("0");
    }
    if (role === "DRIVER") {
      setValue("7");
    }
    if (role === "MINSOC") {
      setValue("8");
    }
    if (role === "ADMIN") {
      setValue("8");
    }
    if (role === "VLADA") {
      setValue("0");
    }
  }, [role]);
  const [tabsCALLCENTER] = React.useState([
    <Tab label="Записати" value="0" index={"0"} key={0} />,
    <Tab label="Перевірити" value="1" index={"1"} key={1} />,
    <Tab label="Записи" value="6" index={"6"} key={6} />,
    // <Tab label="Записи" value="9" index={"9"} key={9} />,
  ]);

  const [tabsCOORDINATOR] = React.useState([
    <Tab label="Записати" value="0" index={"0"} key={0} />,
    <Tab label="Перевірити" value="1" index={"1"} key={1} />,
    <Tab label="Записи" value="9" index={"9"} key={9} />,

    // <Tab label="Додати громаду" value="2" index={"2"} key={2} />,
    // <Tab label="Оновити громаду" value="3" index={"3"} key={3} />,
    // <Tab label="Дані по громаді" value="4" index={"4"} key={4} />,
    <Tab label="Призначити перевізника" value="5" index={"5"} key={5} />,
    <Tab label="Записи" value="6" index={"6"} key={6} />,
  ]);

  const [tabsVLADA] = React.useState([
    <Tab label="Записати" value="0" index={"0"} key={0} />,
    <Tab label="Перевірити" value="1" index={"1"} key={1} />,
    <Tab label="Записи" value="9" index={"9"} key={9} />,

    // <Tab label="Додати громаду" value="2" index={"2"} key={2} />,
    // <Tab label="Оновити громаду" value="3" index={"3"} key={3} />,
    // <Tab label="Дані по громаді" value="4" index={"4"} key={4} />,
    <Tab label="Записи" value="6" index={"6"} key={6} />,
  ]);

  const [tabsDRIVER] = React.useState([
    <Tab label="Волонтер-перевізник" value="7" index={"7"} key={7} />,
    <Tab label="Реєстрація авто" value="10" index={"10"} key={10} />,
  ]);

  const [tabsMINSOC] = React.useState([
    <Tab label="Записи" value="8" index={"8"} key={8} />,
  ]);

  const [tabsADMIN] = React.useState([
    <Tab label="Записи" value="8" index={"8"} key={8} />,
    <Tab label="Автівки" value="11" index={"11"} key={11} />,
  ]);

  return (
    <Box sx={{ mx: "auto", p: 1, m: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        // centered
      >
        {role === "CALLCENTER" || role === "UKRZALIZNYTSIA"
          ? tabsCALLCENTER.map((child) => child)
          : null}
        {role === "COORDINATOR" ? tabsCOORDINATOR.map((child) => child) : null}
        {role === "VLADA" ? tabsVLADA.map((child) => child) : null}
        {role === "DRIVER" ? tabsDRIVER.map((child) => child) : null}
        {role === "MINSOC" ? tabsMINSOC.map((child) => child) : null}
        {role === "ADMIN" ? tabsADMIN.map((child) => child) : null}
      </Tabs>

      {value === "0" ? <AddApplication /> : null}
      {value === "1" ? <Search /> : null}

      {value === "2" ? <AddCommunity /> : null}
      {value === "3" ? <UpdateCommunity /> : null}
      {value === "4" ? <InfoCommunity /> : null}
      {value === "5" ? <ChooseCarrier /> : null}
      {value === "6" ? <ApplicationCoordinator /> : null}
      {value === "7" ? <Carrier /> : null}
      {value === "8" ? <Application /> : null}
      {value === "9" ? <ApplicationCallcenter /> : null}
      {value === "10" ? <CarrierCar /> : null}
      {value === "11" ? <CarrierCarInfo /> : null}
    </Box>
  );
}
