import $api from "../http";

export default class CarService {
  static async create(brand, color, carNumber, driverFullName, passport) {
    return $api.post("/car", {
      brand,
      color,
      carNumber,
      driverFullName,
      passport,
    });
  }

  static async getByDriver() {
    return $api.post("/car/findByDriver");
  }

  static async getAll() {
    return $api.get("/car");
  }
}
