import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

import ReactInputMask from "react-input-mask";
import ApplicationService from "../../services/ApplicationService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Search() {
  const [values, setValues] = useState({
    surname: "",
    name: "",
    patronymic: "",
    inn: "",
    idSerial: "",
    phone: "",
    address: "",
    city: "",

    status: "",
  });

  const [fiend, setFiend] = useState(false);
  const [applications, setApplications] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const checkInputs = (values) => {
    if (values.phone.length !== 13 || values.phone.length < 1) {
      setErrorPhone("Помилка!");
      return true;
    }
    setErrorPhone("");

    return false;
  };

  const searchAction = (values) => {
    if (checkInputs(values)) return;
    setLoading(true);

    ApplicationService.findByPhone(values.phone)
      .then((response) => {
        setFiend(true);
        setApplications(response.data.applications);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteAction = (id) => {
    setLoading(true);
    ApplicationService.delete(id)
      .then((response) => {
        setOpen(true);
        setLoading(false);
        setApplications([]);
        setFiend(false);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setApplications([]);
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <ReactInputMask
        mask="+389999999999"
        value={values.phone}
        disabled={false}
        maskChar=""
        onChange={handleChange("phone")}
      >
        {() => (
          <TextField
            error={errorPhone.length > 0 ? true : false}
            helperText={errorPhone}
            label="Номер телефону"
            placeholder="+380123456789"
          />
        )}
      </ReactInputMask>

      <Button
        variant="outlined"
        color="secondary"
        startIcon={<SearchIcon />}
        onClick={() => {
          searchAction(values);
        }}
      >
        Перевірити
      </Button>

      {loading ? <CircularProgress /> : null}

      {applications.length < 1 && fiend ? (
        <Typography>Нема записів</Typography>
      ) : null}

      {applications.map((application, i) => {
        const blackList = application.address.includes("*ЧС*");
        return (
          <Accordion key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={blackList ? { backgroundColor: "red" } : null}
            >
              <Typography>
                ПІБ: {application.surname} {application.name}{" "}
                {application.patronymic} Час створення заявки:{" "}
                {application.createdAt
                  ? moment(application.createdAt).format("DD.MM.yyyy HH:mm:ss")
                  : "-"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                ...(blackList ? { backgroundColor: "red" } : {}),
              }}
            >
              <Typography>
                ПІБ: {application.surname} {application.name}{" "}
                {application.patronymic}
              </Typography>
              <Typography>Номер телефону: {application.phone}</Typography>
              <Typography>Місто: {application.city}</Typography>
              <Typography>Адреса: {application.address}</Typography>
              <Typography>Поселення: {application.status}</Typography>

              <Typography>
                Громада що приймає: {application.communityId.name}
              </Typography>

              <Typography>
                Інвалідність: {application.disabled ? "Так" : "Ні"}
              </Typography>
              <Typography>
                Спецтранспорт: {application.limitedMobility ? "Так" : "Ні"}
              </Typography>
              <Typography>Коментар: {application.comment}</Typography>
              <Typography>
                Організація: {application.organizationId.name}
              </Typography>
              <Typography>Волонтер: {application.userId.fullName}</Typography>
              <Typography>
                Дата: {moment(application.createdAt).format("DD.MM.YYYY")}
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  deleteAction(application._id);
                }}
              >
                Видалити
              </Button>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Все успішно!
        </Alert>
      </Snackbar>
    </Paper>
  );
}
