import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Collapse,
  List,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { TransitionGroup } from "react-transition-group";
import CustomDataGrid from "../CustomDataGrid";

import CarService from "../../services/CarService";

export default function CarrierCarInfo() {
  const scrollToTopRef = useRef(null);

  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [editBox, setEditBox] = useState({ row: null, visible: false });
  const [dataRows, setDataRows] = useState([]);
  const [startDate, setStartDate] = useState(Date());
  const [endDate, setEndDate] = useState(Date());
  const [dateError, setDateError] = useState(false);

  const columns = [
    // { field: "_id", headerName: "id", width: 90 },
    { field: "brand", headerName: "Марка", width: 200 },
    { field: "color", headerName: "Колір", width: 200 },
    { field: "carNumber", headerName: "Номер", width: 200 },
    { field: "driverFullName", headerName: "ПІБ", width: 150 },
    { field: "passport", headerName: "Паспорт", width: 150 },
  ];

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, []);

  const refreshData = () => {
    setDataGridLoading(true);

    CarService.getAll()
      .then((response) => {
        setDataRows(response.data.cars);
        setDataGridLoading(false);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
        setDataGridLoading(false);
      });
  };

  return (
    <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
      <Paper sx={{ width: "90%", mb: 3, p: 2 }} ref={scrollToTopRef}>
        <List>
          <TransitionGroup>
            <Collapse>
              <CustomDataGrid
                columns={columns}
                rows={dataRows}
                loading={dataGridLoading}
              ></CustomDataGrid>
            </Collapse>
          </TransitionGroup>
        </List>
      </Paper>
    </Box>
  );
}
