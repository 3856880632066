import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Toolbar, AppBar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import AccountIcon from "@mui/icons-material/AccountCircle";
import AuthService from "../services/AuthService";
import Logo from "../logo.png";
import { Box } from "@mui/system";
export default function Navbar(props) {
  const navigate = useNavigate();
  function logout() {
    AuthService.logout()
      .then((response) => {
        localStorage.removeItem("token");
        props.setUpdate(!props.update);
        navigate("/login");
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  }
  return (
    <AppBar position="static">
      <Toolbar>
        <Box component={Link} to="">
          <Box
            component="img"
            sx={{
              height: 50,
              maxHeight: { xs: 40, md: 50 },
              mr: 2,
            }}
            alt="The house from the offer."
            src={Logo}
          />
        </Box>
        <Typography
          variant="h6"
          color="inherit"
          component={Link}
          to=""
          sx={{ flexGrow: 1 }}
          style={{ textDecoration: "none" }}
        >
          Евакуація
        </Typography>

        {props.auth ? (
          <div>
            {/* <Button
              color="inherit"
              endIcon={<StorageIcon />}
              component={Link}
              to="/tables"
            >
              Таблиці
            </Button> */}
            <Button
              color="inherit"
              endIcon={<AccountIcon />}
              component={Link}
              to="/profile"
            >
              Профіль
            </Button>
            <Button
              color="inherit"
              endIcon={<LogoutIcon />}
              onClick={() => {
                logout();
              }}
            >
              Вийти
            </Button>
            <Typography sx={{
               textOverflow: "ellipsis",
               overflow: "hidden",
               width: "200px",
               whiteSpace: "nowrap",
            }}>{localStorage.getItem("fullName")}</Typography>
          </div>
        ) : (
          <>
            <Button
              color="inherit"
              component={Link}
              to="/login"
              endIcon={<LoginIcon />}
            >
              Логін
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/registration"
              endIcon={<LoginIcon />}
            >
              Реєстрація
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
