import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {  Paper,  Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function Profile(props) {
  const [role, setRole] = useState("");
  // const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();

  useEffect(() => {
    const roleStorage = localStorage.role
    if (roleStorage === "CALLCENTER") {
      setRole("Волонтёр кол-центра");
    }
    if (roleStorage === "UKRZALIZNYTSIA") {
      setRole("Волонтёр Укрзалізниця");
    }
    if (roleStorage === "COORDINATOR") {
      setRole("Координатор");
    }
    if (roleStorage === "DRIVER") {
      setRole("Волонтёр перевозчик");
    }
    if (roleStorage === "MINSOC") {
      setRole("МінСоцПолітики");
    }
    if (roleStorage === "ADMIN") {
      setRole("Адмін");
    }
  }, []);

  return (
    <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
      <Paper maxWidth="md" sx={{ width: "620px", mb: 3, p: 2 }}>
      
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
          >
            <Typography>{"ПІБ:"}</Typography>
            <Typography>{localStorage.fullName}</Typography>
            <Typography>{"Роль:"}</Typography>
            <Typography>{role}</Typography>
            
            {/* {loading ? <CircularProgress /> : null} */}

          </Box>
     
      </Paper>
    </Box>
  );
}
