import {
  Button,
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ReactInputMask from "react-input-mask";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import RegionService from "../../services/RegionService";
import CommunityService from "../../services/CommunityService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddCommunity() {
  const [values, setValues] = useState({
    region: "",
    name: "",
    amount: 0,
    contact: "",
    phone: "",
  });

  const [regions, setRegions] = useState({ options: [] });

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState("");
  const [error, setError] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const checkInputs = (values) => {
    if (values.phone.length !== 13 || values.phone.length < 1) {
      setErrorPhone("Помилка!");
      return true;
    }
    setErrorPhone("");

    setError("");
    return false;
  };

  const addAction = (values) => {
    if (checkInputs(values)) return;
    setLoading(true);

    CommunityService.create(
      values.name,
      regions.options.find((region) => region.label === values.region),
      values.amount,
      values.contact,
      values.phone
    )
      .then((response) => {
        setOpen(true);
        setValues({
          region: "",
          name: "",
          amount: 0,
          contact: "",
          phone: "",
        });
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    // to find id of region
    // regions.options.find((region) => region.label == event.target.value);
  };

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    RegionService.getAll()
      .then((response) => {
        const regions = {
          options: response.data.regions.map((region) => {
            return { label: region.name, _id: region._id };
          }),
        };
        setRegions(regions);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <Autocomplete
        disablePortal
        {...regions}
        renderInput={(params) => (
          <TextField
            {...params}
            value={values.region}
            onChange={handleChange("region")}
            onSelect={handleChange("region")}
            label="Область"
          />
        )}
      />

      <TextField
        id="outlined-basic"
        label="Назва громади"
        variant="outlined"
        value={values.name}
        onChange={handleChange("name")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <TextField
        id="outlined-basic"
        label="Загальна кількість місць"
        variant="outlined"
        value={values.amount}
        onChange={handleChange("amount")}
        type="number"
      />

      <TextField
        id="outlined-basic"
        label="Контактна особа"
        variant="outlined"
        value={values.contact}
        onChange={handleChange("contact")}
        error={error.length > 0 ? true : false}
        helperText={error}
        inputProps={{ style: { textTransform: "capitalize" } }}
      />

      <ReactInputMask
        mask="+389999999999"
        value={values.phone}
        disabled={false}
        maskChar=""
        onChange={handleChange("phone")}
      >
        {() => (
          <TextField
            error={errorPhone.length > 0 ? true : false}
            helperText={errorPhone}
            label="Номер контактної особи"
            placeholder="+380123456789"
          />
        )}
      </ReactInputMask>

      <Button
        variant="outlined"
        color="secondary"
        startIcon={<EditIcon />}
        onClick={() => {
          addAction(values);
        }}
      >
        Додати
      </Button>

      {loading ? <CircularProgress /> : null}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Все успішно!
        </Alert>
      </Snackbar>
    </Paper>
  );
}
