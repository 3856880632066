import $api from "../http";
export default class AuthService {
  static async login(email, password) {
    return $api.post("/user/login", { email, password });
  }

  static async registration(email, password, fullName, organization, code) {
    return $api.post("/user/registration", {
      email,
      password,
      fullName,
      organization,
      code,
    });
  }

  static async logout() {
    return $api.post("/user/logout");
  }
}
