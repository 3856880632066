import {
  Button,
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import moment from "moment";

import EditIcon from "@mui/icons-material/Edit";
import RegionService from "../../services/RegionService";
import CommunityService from "../../services/CommunityService";
import RestrictionsService from "../../services/RestrictionsService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UpdateCommunity() {
  const [values, setValues] = useState({
    region: "",
    community: "",
    amountToday: 0,
    quantityLimitedMobilityRestrictions: 0,
    amount: 0,
  });
  const [regions, setRegions] = useState({ options: [] });
  const [communities, setCommunities] = useState({ options: [] });

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const updateAction = (values) => {
    setLoading(true);

    RestrictionsService.create(
      values.amountToday,
      values.quantityLimitedMobilityRestrictions,
      moment(
        moment(Date()).add(1, "days").format("DD.MM.YYYY"),
        "DD.MM.YYYY"
      ).toDate(),
      communities.options.find(
        (community) => community.label === values.community
      )._id
    )
      .then((response) => {
        setLoading(false);
        setOpen(true);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
    CommunityService.editAmount(
      communities.options.find(
        (community) => community.label === values.community
      )._id,
      values.amount
    )
      .then((response) => {
        getCommunities(
          regions.options.find((region) => region.label === values.region)._id
        );
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCommunities = (id) => {
    CommunityService.getByRegion(id)
      .then((response) => {
        const communities = {
          options: response.data.communities.map((community) => {
            return {
              label: community.name,
              _id: community._id,
              amount: community.amount,
            };
          }),
        };
        setCommunities(communities);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "region") {
      try {
        getCommunities(
          regions.options.find((region) => region.label === event.target.value)
            ._id
        );
      } catch (error) {}
    }
    if (prop === "community") {
      try {
        RestrictionsService.getInfo(
          communities.options.find(
            (community) => community.label === event.target.value
          )._id,

          moment(
            moment(Date()).add(1, "days").format("DD.MM.YYYY"),
            "DD.MM.YYYY"
          ).toDate()
        )
          .then((response) => {
            const restrictions = response.data.restrictions;
            setValues({
              ...values,
              [prop]: event.target.value,
              amount: communities.options.find(
                (community) => community.label === event.target.value
              ).amount,
              amountToday:
                restrictions != null ? restrictions.quantityRestrictions : 0,
            });
          })
          .catch((e) => {
            console.log(e.response?.data?.message);
          });
      } catch (error) {}
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    RegionService.getAll()
      .then((response) => {
        const regions = {
          options: response.data.regions.map((region) => {
            return { label: region.name, _id: region._id };
          }),
        };
        setRegions(regions);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <Autocomplete
        disablePortal
        {...regions}
        renderInput={(params) => (
          <TextField
            {...params}
            value={values.region}
            onChange={handleChange("region")}
            onSelect={handleChange("region")}
            label="Область"
          />
        )}
      />

      <Autocomplete
        disablePortal
        {...communities}
        renderInput={(params) => (
          <TextField
            {...params}
            value={values.community}
            onSelect={handleChange("community")}
            label="Громада"
          />
        )}
      />
      <TextField
        id="outlined-basic"
        label={`Кількість місць на ${moment(Date())
          .add(1, "days")
          .format("DD.MM.YYYY")}`}
        variant="outlined"
        value={values.amountToday}
        onChange={handleChange("amountToday")}
        type="number"
      />

      <TextField
        id="outlined-basic"
        label={`Кількість маломобільних місць на ${moment(Date())
          .add(1, "days")
          .format("DD.MM.YYYY")}`}
        variant="outlined"
        value={values.quantityLimitedMobilityRestrictions}
        onChange={handleChange("quantityLimitedMobilityRestrictions")}
        type="number"
      />

      <TextField
        id="outlined-basic"
        label="Загальна кількість місць"
        variant="outlined"
        value={values.amount}
        onChange={handleChange("amount")}
        type="number"
      />

      <Button
        variant="outlined"
        color="secondary"
        startIcon={<EditIcon />}
        onClick={() => {
          updateAction(values);
        }}
      >
        Оновити
      </Button>

      {loading ? <CircularProgress /> : null}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Все успішно!
        </Alert>
      </Snackbar>
    </Paper>
  );
}
