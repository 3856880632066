import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";

import { Collapse, List, Paper } from "@mui/material";

import { TransitionGroup } from "react-transition-group";
import ApplicationService from "../../services/ApplicationService";
import moment from "moment";

import { DataGrid, ukUA } from "@mui/x-data-grid";

export default function ApplicationCallcenter() {
  const scrollToTopRef = useRef(null);

  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [dataRows, setDataRows] = useState([]);

  const columns = [
    // { field: "_id", headerName: "id", width: 90,display: "none"  },
    { field: "surname", headerName: "Прізвище", width: 200 },
    { field: "name", headerName: "Ім'я", width: 200 },
    { field: "patronymic", headerName: "По батькові", width: 200 },
    { field: "city", headerName: "Місто", width: 200 },
    { field: "address", headerName: "Адреса", width: 200 },
    {
      field: "birthday",
      headerName: "День народженя",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy HH:mm:ss Z");
      },
    },
    { field: "phone", headerName: "Номер телефону", width: 150 },
    { field: "status", headerName: "Стан", width: 200 },
    { field: "comment", headerName: "Коментар", width: 200 },

    { field: "disabled", headerName: "disabled", width: 200 },
    { field: "limitedMobility", headerName: "limitedMobility", width: 200 },

    {
      field: "organization",
      headerName: "Організация",

      width: 200,
      valueGetter: (params) => {
        if (params.row?.organizationId?.name) {
          return String(params.row?.organizationId?.name);
        } else {
          return "";
        }
      },
      type: "string",
    },
    {
      field: "evacuationDirection",
      headerName: "Напрямок евакуації",
      width: 300,
    },
    {
      field: "evacuationTime",
      headerName: "День евакуації",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy");
      },
    },
    // {
    //   field: "regionId",
    //   headerName: "Область",

    //   width: 200,
    //   valueGetter: (params) => {
    //     if (params.row?.communityId?.regionId) {
    //       return String(params.row?.communityId?.regionId?.name);
    //     } else {
    //       return "";
    //     }
    //   },
    //   type: "string",
    // },

    // {
    //   field: "communityId",
    //   headerName: "Громада",

    //   width: 200,
    //   valueGetter: (params) => {
    //     if (params.row?.communityId?.name) {
    //       return String(params.row?.communityId?.name);
    //     } else {
    //       return "";
    //     }
    //   },
    //   type: "string",
    // },
    {
      field: "userId",
      headerName: "Волонтер",

      width: 300,
      valueGetter: (params) => {
        if (params.row?.userId?.fullName) {
          return String(params.row?.userId?.fullName);
        } else {
          return "";
        }
      },
      type: "string",
    },
  ];

  useEffect(() => {
    refreshData();

    // eslint-disable-next-line
  }, []);

  const refreshData = () => {
    setDataGridLoading(true);

    ApplicationService.findByDate(
      moment(moment(Date()).format("DD.MM.YYYY"), "DD.MM.YYYY").toDate()
    )
      .then((response) => {
        setDataRows(response.data.applications);
        setDataGridLoading(false);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
        setDataGridLoading(false);
      });
  };

  return (
    <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
      <Paper sx={{ width: "90%", mb: 3, p: 2 }} ref={scrollToTopRef}>
        <List>
          <TransitionGroup>
            <Collapse>
              <DataGrid
                autoHeight
                rows={dataRows}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[5, 10, 50]}
                // checkboxSelection
                disableSelectionOnClick
                // density={"standard"}
                loading={dataGridLoading}
                getRowId={(row) => row._id}
                localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
              />
            </Collapse>
          </TransitionGroup>
        </List>
      </Paper>
    </Box>
  );
}
