import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
// import Tables from "./components/Tables";
import Home from "./components/Home";
import Registration from "./components/Registration";
import { Box, Container, Typography } from "@mui/material";
import Profile from "./components/Profile";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const [update, setUpdate] = useState(false);
  //TODO: auth check
  const auth = localStorage.getItem("token");
  function PrivateRoute({ children }) {
    return auth ? children : <Navigate to="/login" />;
  }
  return (
    <BrowserRouter>
      <Navbar auth={auth} setUpdate={setUpdate} update={update} />
      <Box sx={{ mb: 6 }}>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/tables"
            element={
              <PrivateRoute>
                <Tables />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/login"
            element={<Login setUpdate={setUpdate} update={update} />}
          />
          <Route
            path="/registration"
            element={<Registration setUpdate={setUpdate} update={update} />}
          />
        </Routes>
      </Box>
      <Container
        maxWidth="false"
        sx={{
          width: "100%",
          backgroundColor: "#1976d2",
          pt: 2,
          pb: 2,
          textAlign: "center",
          ...(!isMobile ? { position: "absolute", bottom: 0 } : {}),
        }}
      >
        <Typography sx={{ color: "white" }}>
          Програма розроблена за рахунок фінансування ГС «Асоціація гуманітарний
          центр «Все буде добре» для ведення гуманітарної діяльності та є її
          власністю асоціації. &copy; 2023 Всі права захищені
        </Typography>
      </Container>
    </BrowserRouter>
  );
}

export default App;
