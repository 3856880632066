import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import ReactInputMask from "react-input-mask";
import { TransitionGroup } from "react-transition-group";
import CustomDataGrid from "../CustomDataGrid";
import ApplicationService from "../../services/ApplicationService";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ukLocale from "date-fns/locale/uk";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import OrganizationService from "../../services/OrganizationService";

export default function ApplicationCoordinator() {
  const scrollToTopRef = useRef(null);
  const executeScroll = () => scrollToTopRef.current.scrollIntoView();

  const [organizations, setOrganizations] = useState({ options: [] });

  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [editBox, setEditBox] = useState({ row: null, visible: false });
  const [dataRows, setDataRows] = useState([]);
  const [startDate, setStartDate] = useState(Date());
  const [endDate, setEndDate] = useState(Date());
  const [dateError, setDateError] = useState(false);

  const [errorCity, setErrorCity] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [error, setError] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const columns = [
    // { field: "_id", headerName: "id", width: 90 },
    {
      field: "deactivated",
      headerName: "Активна",
      width: 200,
      valueGetter: (params) => {
        if (params.row?.deactivated) {
          return "Ні";
        } else {
          return "Так";
        }
      },
    },
    { field: "surname", headerName: "Прізвище", width: 200 },
    { field: "name", headerName: "Ім'я", width: 200 },
    { field: "patronymic", headerName: "По батькові", width: 200 },
    { field: "city", headerName: "Місто", width: 200 },
    { field: "address", headerName: "Адреса", width: 200 },
    {
      field: "birthday",
      headerName: "День народженя",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy");
      },
    },
    { field: "phone", headerName: "Номер телефону", width: 150 },
    { field: "status", headerName: "Стан", width: 200 },
    { field: "comment", headerName: "Коментар", width: 200 },

    {
      field: "disabled",
      headerName: "Інвалідність",
      width: 200,
      valueGetter: (params) => {
        if (params.row?.disabled) {
          return "Так";
        } else {
          return "Ні";
        }
      },
    },
    {
      field: "limitedMobility",
      headerName: "Обмежена мобільність",
      width: 200,
      valueGetter: (params) => {
        if (params.row?.limitedMobility) {
          return "Так";
        } else {
          return "Ні";
        }
      },
    },

    {
      field: "organization",
      headerName: "Організация",

      width: 200,
      valueGetter: (params) => {
        if (params.row?.organizationId?.name) {
          return String(params.row?.organizationId?.name);
        } else {
          return "";
        }
      },
      type: "string",
    },
    {
      field: "regionId",
      headerName: "Область",

      width: 200,
      valueGetter: (params) => {
        if (params.row?.communityId?.regionId) {
          return String(params.row?.communityId?.regionId?.name);
        } else {
          return "";
        }
      },
      type: "string",
    },

    // {
    //   field: "communityId",
    //   headerName: "Громада",

    //   width: 200,
    //   valueGetter: (params) => {
    //     if (params.row?.communityId?.name) {
    //       return String(params.row?.communityId?.name);
    //     } else {
    //       return "";
    //     }
    //   },
    //   type: "string",
    // },
    {
      field: "evacuationDirection",
      headerName: "Напрямок евакуації",
      width: 300,
    },
    {
      field: "evacuationTime",
      headerName: "День евакуації",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy");
      },
    },
    {
      field: "userId",
      headerName: "Волонтер",

      width: 300,
      valueGetter: (params) => {
        if (params.row?.userId?.fullName) {
          return String(params.row?.userId?.fullName);
        } else {
          return "";
        }
      },
      type: "string",
    },

    {
      field: "createdAt",
      headerName: "Створено",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy HH:mm:ss Z");
      },
    },
    {
      field: "updatedAt",
      headerName: "Оновлено",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy HH:mm:ss Z");
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => {
            setEditBox((prev) => ({
              row: params.row,
              visible: true,
            }));
            executeScroll();
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => {
            deleteAction(params.row._id);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const deleteAction = (id) => {
    setDataGridLoading(true);
    ApplicationService.delete(id)
      .then((response) => {
        // setOpen(true);
        refreshData();
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setDataGridLoading(false);
      });
  };

  useEffect(() => {
    if (moment(endDate).diff(moment(startDate), "days") <= 31) {
      setDateError(false);
      refreshData();
    } else {
      setDateError(true);
      setDataRows([]);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  useEffect(() => {
    refreshData();
    OrganizationService.getAll()
      .then((response) => {
        const organizations = {
          options: response.data.organizations.map((organization) => {
            return { label: organization.name, _id: organization._id };
          }),
        };
        setOrganizations(organizations);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
    // eslint-disable-next-line
  }, []);

  const refreshData = () => {
    setDataGridLoading(true);

    ApplicationService.getAllByOrganizationBetweenDates(
      moment(startDate).format("DD.MM.YYYY "),
      moment(endDate).add(1, "d").format("DD.MM.YYYY ")
    )
      .then((response) => {
        setDataRows(response.data.applications);
        setDataGridLoading(false);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
        setDataGridLoading(false);
      });
  };

  const EditBox = (props) => {
    const [values, setValues] = useState({
      id: props.row?._id,
      surname: "",
      name: "",
      patronymic: "",
      city: "",
      address: "",
      ipn: "",
      passport: "",
      passportWhenIssued: "",
      passportByWhomWasIssued: "",
      birthday: Date(),
      phone: "",
      status: false,
      comment: "",
      disabled: false,
      children: false,
      payment: false,
      limitedMobility: false,
      community: "",
      organization: "",
      evacuationDirection: "",
      evacuationTime: "",
    });
    useEffect(() => {
      setValues({
        id: props.row?._id,
        surname: props.row?.surname,
        name: props.row?.name,
        patronymic: props.row?.patronymic,
        city: props.row?.city,
        address: props.row?.address,
        ipn: props.row?.ipn,
        passport: props.row?.passport,
        passportWhenIssued: props.row?.passportWhenIssued,
        passportByWhomWasIssued: props.row?.passportByWhomWasIssued,
        birthday: props.row?.birthday,
        phone: props.row?.phone,
        status: props.row?.status === "З поселенням" ? true : false,
        comment: props.row?.comment,
        disabled: props.row?.disabled,
        children: props.row?.children,
        payment: props.row?.payment,
        limitedMobility: props.row?.limitedMobility,
        organization: props.row?.organization,
        evacuationDirection: props.row?.evacuationDirection,
        evacuationTime: props.row?.evacuationTime,
      });
    }, [props.row]);

    const checkInputs = (values) => {
      if (
        values.surname.length < 1 ||
        values.name.length < 1 ||
        values.patronymic.length < 1
      ) {
        setError("Усі поля мають бути заповнені!");
        return true;
      }
      setError("");
      return false;
    };

    function edit(params) {
      if (checkInputs(params)) return;
      ApplicationService.edit(
        params.id,
        params.surname,
        params.name,
        params.patronymic,
        params.city,
        params.address,
        params.ipn,
        params.passport,
        params.passportWhenIssued,
        params.passportByWhomWasIssued,
        params.birthday,
        params.phone,
        params.status ? "З поселенням" : "Без поселення",
        params.comment,
        params.disabled,
        params.children,
        params.payment,
        params.limitedMobility,
        params.organization,
        params.evacuationDirection,
        params.evacuationTime
      )
        .then((response) => {
          refreshData();
          setEditBox((prev) => ({
            row: null,
            visible: false,
          }));
        })
        .catch((e) => {
          console.log(e.response?.data?.message);
        });
    }

    const handleChange = (prop) => (event) => {
      if (
        prop === "limitedMobility" ||
        prop === "disabled" ||
        prop === "status" ||
        prop === "verify" ||
        prop === "children" ||
        prop === "payment"
      ) {
        setValues({ ...values, [prop]: event.target.checked });
      } else if (
        prop === "birthday" ||
        prop === "evacuationTime" ||
        prop === "passportWhenIssued"
      ) {
        setValues({ ...values, [prop]: event });
      } else {
        setValues({ ...values, [prop]: event.target.value });
      }
    };

    return (
      <Paper
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="id"
          variant="outlined"
          disabled={true}
          value={values.id ? values.id : "id will be created"}
        />

        <TextField
          id="outlined-basic"
          label="Напрямок евакуації"
          variant="outlined"
          value={values.evacuationDirection}
          onChange={handleChange("evacuationDirection")}
          error={error.length > 0 ? true : false}
          helperText={error}
        />

        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ukLocale}
        >
          <DesktopDatePicker
            label="День евакуації"
            value={values.evacuationTime}
            mask={"__.__.____"}
            minDate={new Date("2023-01-01")}
            onChange={handleChange("evacuationTime")}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <TextField
          id="outlined-basic"
          label="Прізвище"
          variant="outlined"
          value={values.surname}
          onChange={handleChange("surname")}
          error={error.length > 0 ? true : false}
          helperText={error}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />

        <TextField
          id="outlined-basic"
          label="Ім'я"
          variant="outlined"
          value={values.name}
          onChange={handleChange("name")}
          error={error.length > 0 ? true : false}
          helperText={error}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />

        <TextField
          id="outlined-basic"
          label="По батькові"
          variant="outlined"
          value={values.patronymic}
          onChange={handleChange("patronymic")}
          error={error.length > 0 ? true : false}
          helperText={error}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />

        <ReactInputMask
          mask="9999999999"
          value={values.ipn}
          disabled={false}
          maskChar=""
          onChange={handleChange("ipn")}
        >
          {() => <TextField label="ІПН" placeholder="9999999999" />}
        </ReactInputMask>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.payment}
                onChange={handleChange("payment")}
              />
            }
            label="Отримує виплату"
          />
        </FormGroup>
        {values.payment ? (
          <TextField
            id="outlined-basic"
            label="Паспорт серія (за наявності), номер, Ким видан, Коли видан"
            variant="outlined"
            value={values.passport}
            onChange={handleChange("passport")}
          />
        ) : null}

        {values.payment ? (
          <TextField
            id="outlined-basic"
            label="Ким був виданий паспорт"
            variant="outlined"
            value={values.passportByWhomWasIssued}
            error={error.length > 0 ? true : false}
            helperText={error}
            onChange={handleChange("passportByWhomWasIssued")}
          />
        ) : null}

        {values.payment ? (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ukLocale}
          >
            <DesktopDatePicker
              label="Коли був виданий паспорт"
              value={values.passportWhenIssued}
              mask={"__.__.____"}
              minDate={new Date("1900-01-01")}
              onChange={handleChange("passportWhenIssued")}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        ) : null}

        <TextField
          id="outlined-basic"
          label="Місто"
          placeholder="Краматорськ"
          variant="outlined"
          value={values.city}
          error={errorCity.length > 0 ? true : false}
          helperText={errorCity}
          onChange={handleChange("city")}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />

        <TextField
          id="outlined-basic"
          label="Адреса"
          placeholder="Машинобудівників 49"
          variant="outlined"
          value={values.address}
          error={errorAddress.length > 0 ? true : false}
          helperText={errorAddress}
          onChange={handleChange("address")}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />

        <ReactInputMask
          mask="+389999999999"
          value={values.phone}
          disabled={false}
          maskChar=""
          onChange={handleChange("phone")}
        >
          {() => (
            <TextField
              error={errorPhone.length > 0 ? true : false}
              helperText={errorPhone}
              label="Номер телефону"
              placeholder="+380123456789"
            />
          )}
        </ReactInputMask>

        <Autocomplete
          disablePortal
          {...organizations}
          renderInput={(params) => (
            <TextField
              {...params}
              value={values.organization}
              onSelect={handleChange("organization")}
              label="Організація"
            />
          )}
        />

        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ukLocale}
        >
          <DesktopDatePicker
            label="День народження"
            value={values.birthday}
            mask={"__.__.____"}
            minDate={new Date("1900-01-01")}
            onChange={handleChange("birthday")}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.disabled}
                onChange={handleChange("disabled")}
              />
            }
            label="Особа з інвалідністю"
          />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.children}
                onChange={handleChange("children")}
              />
            }
            label="Дитина"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.limitedMobility}
                onChange={handleChange("limitedMobility")}
              />
            }
            label="Маломобільний"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.status}
                onChange={handleChange("status")}
              />
            }
            label="З поселенням"
          />
        </FormGroup>

        <TextField
          id="outlined-basic"
          label="Коментар"
          placeholder="Інвалід"
          variant="outlined"
          value={values.comment}
          onChange={handleChange("comment")}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />

        {values.id ? (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => {
              edit(values);
            }}
          >
            Edit
          </Button>
        ) : null}
      </Paper>
    );
  };

  return (
    <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
      <Paper sx={{ width: "90%", mb: 3, p: 2 }} ref={scrollToTopRef}>
        <List>
          <TransitionGroup>
            {editBox.visible ? (
              <Collapse>
                <EditBox row={editBox.row}></EditBox>
              </Collapse>
            ) : null}
            <Collapse
              sx={{
                mb: 2,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    rowGap: "10px",
                    columnGap: "10px",
                  }}
                >
                  <DesktopDatePicker
                    label="Дата початку"
                    value={startDate}
                    mask={"__.__.____"}
                    minDate={new Date("2017-01-01")}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DesktopDatePicker
                    label="Дата кінця"
                    value={endDate}
                    mask={"__.__.____"}
                    minDate={new Date("2017-01-01")}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
              {dateError ? (
                <Typography sx={{ color: "red" }}>
                  Обмеження на проміжок у 31 день
                </Typography>
              ) : null}
            </Collapse>
            <Collapse>
              <CustomDataGrid
                columns={columns}
                rows={dataRows}
                loading={dataGridLoading}
              ></CustomDataGrid>
            </Collapse>
          </TransitionGroup>
        </List>
      </Paper>
    </Box>
  );
}
