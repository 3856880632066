import $api from "../http";

export default class ApplicationService {
  static async create(
    surname,
    name,
    patronymic,
    city,
    address,
    ipn,
    passport,
    passportWhenIssued,
    passportByWhomWasIssued,
    birthday,
    phone,
    status,
    comment,
    disabled,
    children,
    payment,
    limitedMobility,
    evacuationDirection,
    evacuationTime
  ) {
    return $api.post("/application/", {
      surname,
      name,
      patronymic,
      city,
      address,
      ipn,
      passport,
      passportWhenIssued,
      passportByWhomWasIssued,
      birthday,
      phone,
      status,
      comment,
      disabled,
      children,
      payment,
      limitedMobility,
      evacuationDirection,
      evacuationTime,
    });
  }

  static async findByPhoneAndDate(phone, timestamp) {
    return $api.post("/application/findByPhoneAndDate", {
      phone,
      timestamp,
    });
  }

  static async findByPhone(phone) {
    return $api.post("/application/findByPhone", {
      phone,
    });
  }

  static async delete(id) {
    return $api.delete("/application/", {
      data: { id },
    });
  }

  static async getAllBetweenDates(startDate, endDate) {
    return $api.post("/application/all", { startDate, endDate });
  }

  static async getAllBetweenDatesEvacuation(startDate, endDate) {
    return $api.post("/application/allEvacuation", { startDate, endDate });
  }
  static async getAllByOrganizationBetweenDates(startDate, endDate) {
    return $api.post("/application/organization", {
      startDate,
      endDate,
    });
  }

  static async edit(
    id,
    surname,
    name,
    patronymic,
    city,
    address,
    ipn,
    passport,
    passportWhenIssued,
    passportByWhomWasIssued,
    birthday,
    phone,
    status,
    comment,
    disabled,
    children,
    payment,
    limitedMobility,
    organizationId,
    evacuationDirection,
    evacuationTime
  ) {
    return $api.put("/application/", {
      id,
      surname,
      name,
      patronymic,
      city,
      address,
      ipn,
      passport,
      passportWhenIssued,
      passportByWhomWasIssued,
      birthday,
      phone,
      status,
      comment,
      disabled,
      children,
      payment,
      limitedMobility,
      organizationId,
      evacuationDirection,
      evacuationTime,
    });
  }

  static async findByFullNameAndBirthday(surname, name, patronymic, birthday) {
    return $api.post("/application/find", {
      surname,
      name,
      patronymic,
      birthday,
    });
  }

  static async findByIpn(ipn) {
    return $api.post("/application/findByIpn", {
      ipn,
    });
  }

  static async findByDate(timestamp) {
    return $api.post("/application/findByOrganizationAndDate", {
      timestamp,
    });
  }
}
