import $api from "../http";

export default class RestrictionsService {
  static async create(
    quantityRestrictions,
    quantityLimitedMobilityRestrictions,
    timestamp,
    communityId
  ) {
    return $api.post("/restrictions", {
      quantityRestrictions,
      quantityLimitedMobilityRestrictions,
      timestamp,
      communityId,
    });
  }

  static async getInfo(communityId, timestamp) {
    return $api.post("/restrictions/info", { communityId, timestamp });
  }
}
