import {
  Paper,
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import LogisticsService from "../../services/LogisticsService";
import { Box } from "@mui/system";
import moment from "moment";

export default function Carrier() {
  const [driver, setDriver] = useState({
    cityDeparture: "",
    addressDeparture: "",
    timestampDeparture: "",
    destinationAddress: "",
    quantity: "",
  });

  useEffect(() => {
    getDriverInfo();
  }, []);

  const getDriverInfo = () => {
    LogisticsService.getInfo()
      .then((response) => {
        if (response.data.logistic != null) {
          if (response.data.logistic) {
            setDriver(response.data.logistic);
          }
        }
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          alignContent: "center",
        }}
      >
        <Typography>{"Волонтер-перевізник: "}</Typography>
        <br />
        <Typography>{localStorage.getItem("fullName")}</Typography>
      </Box>

      <List>
        <ListItem>
          <ListItemText
            primary={`Місце відправлення: ${driver.cityDeparture} ${driver.addressDeparture}`}
          />
        </ListItem>
        <Divider divider />
        <ListItem>
          <ListItemText
            primary={
              driver.timestampDeparture === ""
                ? "Час відправлення:"
                : `Час відправлення: ${moment(driver.timestampDeparture).format(
                    "DD.MM.YYYY HH:mm"
                  )}`
            }
          />
        </ListItem>
        <Divider divider />
        <ListItem>
          <ListItemText
            primary={`Пункт Призначення: ${driver.destinationAddress}`}
          />
        </ListItem>
        <Divider divider />
        <ListItem>
          <ListItemText primary={`Кількість: ${driver.quantity}`} />
        </ListItem>
      </List>
    </Paper>
  );
}
