import $api from "../http";

export default class LogisticsService {
  static async create(
    userId,
    cityDeparture,
    addressDeparture,
    timestampDeparture,
    destinationAddress,
    quantity
  ) {
    return $api.post("/logistics", {
      userId,
      cityDeparture,
      addressDeparture,
      timestampDeparture,
      destinationAddress,
      quantity,
    });
  }

  static async getInfo() {
    return $api.get("/logistics/info", {});
  }

  static async getInfoByDriver(userId) {
    return $api.post("/logistics/infoByUser", { userId });
  }

  static async getInfoByDrivers() {
    return $api.post("/logistics/infoAllDrivers");
  }
}
