import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Button, CircularProgress, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import AuthService from "../services/AuthService";
import { useEffect } from "react";
import OrganizationService from "../services/OrganizationService";

export default function Registration(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [organization, setOrganization] = useState("");
  const [loading, setLoading] = useState(false);

  const [organizations, setOrganizations] = useState({ options: [] });

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      email.trim() === "" ||
      password.trim() === "" ||
      fullName.trim() === "" ||
      code.trim() === ""
    ) {
      setError("Заповніть всі поля!");
      return;
    }

    if (password !== confirmPassword) {
      setError("Паролі не співпадають!");
      return;
    }

    if (organization === "") {
      setError("Вкажіть організацію");
      return;
    }

    setLoading(true);

    AuthService.registration(email, password, fullName, organization, code)
      .then((response) => {
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("role", response.data.user.role);
        localStorage.setItem("fullName", response.data.user.fullName);

        props.setUpdate(!props.update);
        setError("");
        navigate("/");
      })
      .catch((e) => {
        setError("Щось не так");
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    OrganizationService.getAll().then((response)=>{
      const organizations = {
        options: response.data.organizations.map((organization) => {
          return { label: organization.name, _id: organization._id };
        }),
      };
      setOrganizations(organizations);
    }).catch((e) => {
        console.log(e.response?.data?.message);
    });
  }, []);

  return (
    <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
      <Paper maxWidth="md" sx={{ width: "620px", mb: 3, p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
          >
            <TextField
              fullWidth
              error={error.length > 0 ? true : false}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
              label="email"
              name="email"
              type="text"
              autoFocus
            />

            <TextField
              fullWidth
              error={error.length > 0 ? true : false}
              helperText={error}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              variant="outlined"
              label="Пароль"
              name="password"
              type="password"
            />

            <TextField
              fullWidth
              error={error.length > 0 ? true : false}
              helperText={error}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              variant="outlined"
              label="Повторити пароль"
              name="confirmPassword"
              type="password"
            />

            <TextField
              fullWidth
              error={error.length > 0 ? true : false}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
              variant="outlined"
              label="ПІБ"
              name="fullName"
              type="text"
            />

            <Autocomplete
              disablePortal
              {...organizations}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={organization}
              
                  onSelect={(event)=>{setOrganization(event.target.value)}}
                  label="Організація"
                />
              )}
            />

            <TextField
              fullWidth
              error={error.length > 0 ? true : false}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              variant="outlined"
              label="Код"
              name="code"
              type="text"
            />

            {loading ? <CircularProgress /> : null}

            <Button variant="contained" type="submit">
              Реєстрація
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}
