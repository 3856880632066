import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Collapse,
  List,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { TransitionGroup } from "react-transition-group";
import CustomDataGrid from "../CustomDataGrid";
import ApplicationService from "../../services/ApplicationService";
import moment from "moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ukLocale from "date-fns/locale/uk";
import { downloadExcel } from "react-export-table-to-excel";
import DownloadingIcon from "@mui/icons-material/Downloading";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export default function Application() {
  const scrollToTopRef = useRef(null);
  const tableRef = useRef(null);

  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [editBox, setEditBox] = useState({ row: null, visible: false });
  const [dataRows, setDataRows] = useState([]);
  const [startDate, setStartDate] = useState(Date());
  const [endDate, setEndDate] = useState(Date());
  const [dateError, setDateError] = useState(false);

  const columns = [
    // { field: "_id", headerName: "id", width: 90 },
    {
      field: "pib",
      headerName: "Прізвище, власне ім’я, по батькові",
      width: 300,
      valueGetter: (params) =>
        `${params.row.surname || ""} ${params.row.name || ""} ${
          params.row.patronymic || ""
        }`,
    },

    {
      field: "birthday",
      headerName: "Дата народження",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy");
      },
    },
    {
      field: "disabled",
      headerName: "Особа з інвалідністю",
      width: 100,
      valueGetter: (params) => {
        if (params.row?.disabled) {
          return "Так";
        } else {
          return "Ні";
        }
      },
    },
    {
      field: "children",
      headerName: "Дитина",
      width: 100,
      valueGetter: (params) => {
        if (params.row?.children) {
          return "Так";
        } else {
          return "Ні";
        }
      },
    },
    {
      field: "passport",
      headerName: "Документ, що посвідчує особу ",
      width: 400,
    },
    {
      field: "passportWhenIssued",
      headerName: "Коли виданий",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        if (params.value === null) {
          return "";
        }
        return moment(params.value).format("DD.MM.yyyy");
      },
    },

    {
      field: "passportByWhomWasIssued",
      headerName: "Ким виданий ",
      width: 400,
    },
    {
      field: "ipn",
      headerName: "Реєстрацій-ний номер облікової картки платника податків",
      width: 200,
    },

    {
      field: "afpn",
      headerName: "Адреса фактичного місця проживання, номер телефону",
      width: 400,
      valueGetter: (params) =>
        `${params.row.city || ""}, ${params.row.address || ""}, ${
          params.row.phone || ""
        }`,
    },

    {
      field: "organization",
      headerName: "Організація що зарееструвала",
      width: 400,
      valueGetter: (params) => `${params.row.organizationId.name || ""}`,
    },
    {
      field: "fullName",
      headerName: "Хто зареестрував",
      width: 400,
      valueGetter: (params) => `${params.row.userId.fullName || ""}`,
    },
    {
      field: "evacuationDirection",
      headerName: "Напрямок евакуації",
      width: 400,
      valueGetter: (params) => `${params.row.evacuationDirection || ""}`,
    },

    {
      field: "evacuationTime",
      headerName: "День евакуації",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        return moment(params.value).format("DD.MM.yyyy");
      },
    },
  ];

  useEffect(() => {
    if (moment(endDate).diff(moment(startDate), "days") <= 31) {
      setDateError(false);
      refreshData();
    } else {
      setDateError(true);
      setDataRows([]);
    }
    console.log(dataRows);
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const refreshData = () => {
    setDataGridLoading(true);

    ApplicationService.getAllBetweenDatesEvacuation(
      moment(startDate).format("DD.MM.YYYY "),
      moment(endDate).add(1, "d").format("DD.MM.YYYY ")
    )
      .then((response) => {
        setDataRows(response.data.applications);
        setDataGridLoading(false);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
        setDataGridLoading(false);
      });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsteetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  //dataRows
  function exportToExcel() {
    const columnsForExcel = columns.map((column) => column.headerName);

    const bodyForExcel = dataRows.map((data) => {
      return [
        `${data.surname} ${data.name} ${data.patronymic}`,
        moment(data.birthday).format("DD.MM.yyyy"),
        data.disabled ? "Так" : "Ні",
        data.children ? "Так" : "Ні",
        String(data.passport),
        data.passportWhenIssued === null
          ? ""
          : moment(data.passportWhenIssued).format("DD.MM.yyyy"),
        data.passportByWhomWasIssued,
        data.ipn,
        `${data.city}, ${data.address}, ${data.phone}`,
        data.organizationId.name,
        data.userId.fullName,
        data.evacuationDirection,
        moment(data.evacuationTime).format("DD.MM.yyyy"),
      ];
    });
    const dataExport = [columnsForExcel].concat(bodyForExcel);
    const ws = XLSX.utils.aoa_to_sheet(dataExport);
    function fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
        ),
      }));
    }
    ws["!cols"] = fitToColumn(dataExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `Евакуація nf ${moment(startDate).format("DD.MM.yyyy")} - ${moment(
        endDate
      ).format("DD.MM.yyyy")}` + fileExtension
    );
  }

  const EditBox = (props) => {
    const [values, setValues] = useState({
      id: props.row?._id,
      surname: "",
      name: "",
      patronymic: "",
      ipn: "",
      phone: "",
      delivery: "",
      address: "",
      status: "",
    });
    useEffect(() => {
      setValues({
        id: props.row?._id,
        surname: props.row?.surname,
        name: props.row?.name,
        patronymic: props.row?.patronymic,
        ipn: props.row?.ipn,
        phone: props.row?.phone,
        delivery: props.row?.delivery,
        address: props.row?.address,
        status: props.row?.status,
      });
    }, [props.row]);

    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };

    function edit(params) {
      ApplicationService.put(
        params.id,
        params.surname,
        params.name,
        params.patronymic,
        params.inn,
        params.phone,
        params.delivery,
        params.address,
        params.status
      )
        .then((response) => {
          refreshData();
          setEditBox((prev) => ({
            row: null,
            visible: false,
          }));
        })
        .catch((e) => {
          console.log(e.response?.data?.message);
        });
    }

    function create(params) {
      ApplicationService.create(
        params.surname,
        params.name,
        params.patronymic,
        params.inn,
        params.phone,
        params.delivery,
        params.address,
        params.status
      )
        .then((response) => {
          refreshData();
          setEditBox((prev) => ({
            row: null,
            visible: false,
          }));
        })
        .catch((e) => {
          console.log(e.response?.data?.message);
        });
    }

    return (
      <Paper
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          rowGap: "10px",
        }}
      >
        <TextField
          id="outlined-basic"
          label="id"
          variant="outlined"
          disabled={true}
          value={values.id ? values.id : "id will be created"}
        />
        <TextField
          id="outlined-basic"
          label="surname"
          variant="outlined"
          value={values.surname}
          onChange={handleChange("surname")}
        />
        <TextField
          id="outlined-basic"
          label="name"
          variant="outlined"
          value={values.name}
          onChange={handleChange("name")}
        />
        <TextField
          id="outlined-basic"
          label="patronymic"
          variant="outlined"
          value={values.patronymic}
          onChange={handleChange("patronymic")}
        />
        <TextField
          id="outlined-basic"
          label="inn"
          variant="outlined"
          value={values.inn}
          onChange={handleChange("inn")}
        />
        <TextField
          id="outlined-basic"
          label="phone"
          variant="outlined"
          value={values.phone}
          onChange={handleChange("phone")}
        />
        <TextField
          id="outlined-basic"
          label="delivery"
          variant="outlined"
          value={values.delivery}
          onChange={handleChange("delivery")}
        />
        <TextField
          id="outlined-basic"
          label="address"
          variant="outlined"
          value={values.address}
          onChange={handleChange("address")}
        />
        <TextField
          id="outlined-basic"
          label="status"
          variant="outlined"
          value={values.status}
          onChange={handleChange("status")}
        />

        {values.id ? (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => {
              edit(values);
            }}
          >
            Edit
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => {
              create(values);
            }}
          >
            Create
          </Button>
        )}
      </Paper>
    );
  };

  return (
    <Box sx={{ pt: 2, display: "flex", justifyContent: "center" }}>
      <Paper sx={{ width: "90%", mb: 3, p: 2 }} ref={scrollToTopRef}>
        <List>
          <TransitionGroup>
            <Collapse
              sx={{
                mb: 3,
              }}
            >
              {/* <Button
                variant="outlined"
                color="secondary"
                startIcon={<DownloadingIcon />}
                onClick={() => handleDownloadExcel()}
              >
                Скачати Excel
              </Button> */}
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DownloadingIcon />}
                onClick={(e) => exportToExcel()}
              >
                Скачати Excel
              </Button>
            </Collapse>
            {editBox.visible ? (
              <Collapse>
                <EditBox row={editBox.row}></EditBox>
              </Collapse>
            ) : null}
            <Collapse
              sx={{
                mb: 2,
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    rowGap: "10px",
                    columnGap: "10px",
                  }}
                >
                  <DesktopDatePicker
                    label="Дата початку"
                    value={startDate}
                    mask={"__.__.____"}
                    minDate={new Date("2017-01-01")}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DesktopDatePicker
                    label="Дата кінця"
                    value={endDate}
                    mask={"__.__.____"}
                    minDate={new Date("2017-01-01")}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
              {dateError ? (
                <Typography sx={{ color: "red" }}>
                  Обмеження на проміжок у 31 день
                </Typography>
              ) : null}
            </Collapse>
            <Collapse>
              <CustomDataGrid
                columns={columns}
                rows={dataRows}
                loading={dataGridLoading}
              ></CustomDataGrid>
            </Collapse>
          </TransitionGroup>
        </List>
      </Paper>
    </Box>
  );
}
