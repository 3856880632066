import {
  Paper,
  TextField,
  CircularProgress,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import moment from "moment";
import RegionService from "../../services/RegionService";
import CommunityService from "../../services/CommunityService";

export default function InfoCommunity() {
  const [values, setValues] = useState({
    region: "",
    community: "",
    amountOnDate: 0,
    amount: 0,
    amountAccepted: 0,
  });

  const [regions, setRegions] = useState({ options: [] });
  const [communities, setCommunities] = useState({ options: [] });
  const [loading, setLoading] = React.useState(false);

  const getCommunities = (id) => {
    setLoading(true);
    CommunityService.getByRegion(id)
      .then((response) => {
        const communities = {
          options: response.data.communities.map((community) => {
            return {
              label: community.name,
              _id: community._id,
              amount: community.amount,
            };
          }),
        };
        setCommunities(communities);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "region") {
      try {
        getCommunities(
          regions.options.find((region) => region.label === event.target.value)
            ._id
        );
      } catch (error) {}
    }
    if (prop === "community") {
      try {
        getNumberOfAccepted(
          communities.options.find(
            (community) => community.label === event.target.value
          )._id
        );
      } catch (error) {}
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = () => {
    setLoading(true);
    RegionService.getAll()
      .then((response) => {
        const regions = {
          options: response.data.regions.map((region) => {
            return { label: region.name, _id: region._id };
          }),
        };
        setRegions(regions);
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNumberOfAccepted = (id) => {
    setLoading(true);
    CommunityService.numberOfAccepted(id)
      .then((response) => {
        setLoading(true);
        CommunityService.numberOfFreeOnDate(
          id,
          moment(
            moment(Date()).add(1, "days").format("DD.MM.YYYY"),
            "DD.MM.YYYY"
          ).toDate()
        )
          .then((response2) => {
            setValues({
              ...values,
              amount: communities.options.find(
                (community) => community._id === id
              ).amount,
              amountAccepted: response.data.numberOfAccepted,
              amountOnDate: response2.data.numberOfFreeOnDate,
            });
          })
          .catch((e) => {
            console.log(e.response?.data?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((e) => {
        console.log(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <Autocomplete
        disablePortal
        {...regions}
        renderInput={(params) => (
          <TextField
            {...params}
            value={values.region}
            onSelect={handleChange("region")}
            label="Область"
          />
        )}
      />

      <Autocomplete
        disablePortal
        {...communities}
        renderInput={(params) => (
          <TextField
            {...params}
            value={values.community}
            onSelect={handleChange("community")}
            label="Громада"
          />
        )}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          <ListItem>
            <ListItemText
              primary={`Загальнакількість місць: ${values.amount}`}
            />
          </ListItem>
          <Divider divider />
          <ListItem>
            <ListItemText primary={`Прийнято: ${values.amountAccepted}`} />
          </ListItem>
          <Divider divider />
          <ListItem>
            <ListItemText
              primary={`Вільно на ${moment(Date())
                .add(1, "days")
                .format("DD.MM.YYYY")}: ${values.amountOnDate}`}
            />
          </ListItem>
        </List>
      )}
    </Paper>
  );
}
